import get from 'lodash/get'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import React from 'react'
import styles from './news-post.module.css'

class NewsTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulNewsArticle')

    return (
      <Layout location={this.props.location}>
          <Helmet title={post.title} />
          <div
            className={styles.backgroundHeader}>
            <h2 className={styles.newsHeader}>{post.title}</h2>
            <Img className={styles.headerImage}
                fluid={post.headerBackgroundImage.fluid}
            ></Img>
          </div>
          <div className="wrapper">
            <div className={styles.descriptionText}
                dangerouslySetInnerHTML={{
                    __html: post.description.childMarkdownRemark.html,
                }}
            />
            <div className={styles.imageGrid}>
            {post.images.map((image, index) => {
                return (
                    <Img
                        className={styles.detailImage}
                        fluid={image.fluid}
                        key={index}
                    />
                )
            })}
            </div> 
          </div>
      </Layout>
    )
  }
}

export default NewsTemplate

export const pageQuery = graphql`
  query NewsArticleByTitle($title: String!) {
    contentfulNewsArticle(title: { eq: $title }) {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      images {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      headerBackgroundImage {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
